import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {RenderService} from "@src/app/services/render/render.service";
import {PersistenceService} from "@src/app/services/persistence/persistence.service";
import {PriceCalculatorService} from "@src/app/services/price-calculator/price-calculator.service";
import {NotificationService} from "@src/app/services/notification/notification.service";
import {NotificationCardType} from "@src/app/components/notification-card/notification-card.type";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Icon} from "@src/app/library/components/icon";
import {ModalService} from "@src/app/services/modal/modal.service";
import {RuleService} from "@src/app/services/rule-execution/rule.service";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";
import {ViewMode} from "@src/app/model/view-mode";
import {
  Attribute,
  CheckResult,
  Configuration,
  ConfigurationPlacement,
  ConfiguratorRuleData,
  EventResult,
  FatModuleVariant,
  Id,
  ModulePlacement,
  ModuleType,
  ModuleVariant,
  RuleState,
  UndoConfiguration,
  WoodType,
  XAttachmentPoint,
} from "@ess/jg-rule-executor";
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {take, takeUntil} from "rxjs/operators";
import {AttachmentPointInfo} from "@src/app/model/attachment-point-info";
import {ApolloError} from "@apollo/client/core";
import {HttpBackend, HttpClient, HttpErrorResponse} from "@angular/common/http";
import {MeasurementService} from "@src/app/services/measurement/measurement.service";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {ApolloService} from "@src/app/services/apollo/apollo.service";
import {getReplacementVariant} from "@ess/jg-rule-executor/dist/src/utils/check/deprecation";
import {WebhookFlowType} from "@ess/jg-rule-executor/dist/src/domain/webhook-flow-type";
import {GammaHandlerService} from "@src/app/services/retailer-handlers/gamma-handler.service";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";

@Injectable({
  providedIn: 'root'
})


export class InteractionService implements OnDestroy {
  private _serviceDestroyed$: Subject<void> = new Subject();

  // Note; this might find a better place in the DataService when it is here
  private _configurationChanged$: BehaviorSubject<Configuration> = new BehaviorSubject<Configuration>(undefined);
  private _configurationValidityChanged$: Subject<boolean> = new Subject<boolean>();
  private _configurationAvailabilityChanged$: Subject<boolean> = new Subject<boolean>();
  private _lastSelectedInfo: AttachmentPointInfo;

  private _menuCloser$: Subject<boolean> = new Subject<boolean>();

  private trySaveCount: number = 0;

  private _http: HttpClient;

  constructor(
    private _renderService: RenderService,
    private _moduleManagementService: ModuleManagementService,
    private _priceCalculatorService: PriceCalculatorService,
    private _persistenceService: PersistenceService,
    private _notificationService: NotificationService,
    private _modalService: ModalService,
    private _ruleService: RuleService,
    private _measurementService: MeasurementService,
    private _dataService: InitDataService,
    private _loggingService: LoggingService,
    private _apolloService: ApolloService,
    private _gammaHandlerService: GammaHandlerService,
    private _applicationStateService: ApplicationStateService,
    httpBackend: HttpBackend
  ) {
    this._http = new HttpClient(httpBackend); // This ignores all set interceptors, as scope header might not be allowed for redirect requests

    _dataService.missingVariants$.pipe(takeUntil(this._serviceDestroyed$))
      .subscribe(r => r ? () => {
        _loggingService.warn(`A configuration has been loaded that has missing variants`);
        _notificationService.showNotification('app.notifications.load.out_of_stock', Icon.warn, NotificationCardType.warning);
      } : {});

    this.getConfigurationChanged$().pipe(takeUntil(this._serviceDestroyed$))
      .subscribe(c => c ? _priceCalculatorService.configurationChanged(c) : {});

    _priceCalculatorService.installationServiceChanged$().pipe(takeUntil(this._serviceDestroyed$))
      .subscribe(() => this._configurationChanged$.next(this._configurationChanged$.value));
  }

  public fitCameraToConfiguration(): void {
    this._renderService.fitCameraToConfiguration(this._renderService.getTotalObjectBox());
  }

  /**
   * Getter to indicate configuration changed
   */
  public getConfigurationChanged$(): Observable<Configuration> {
    return this._configurationChanged$.pipe(takeUntil(this._serviceDestroyed$));
  }

  /**
   * Getter to indicate configuration changed
   */
  public getValidityChanged$(): Observable<boolean> {
    return this._configurationValidityChanged$.pipe(takeUntil(this._serviceDestroyed$));
  }

  /**
   * Getter to indicate configuration availability changed
   */
  public getAvailabilityChanged$(): Observable<boolean> {
    return this._configurationAvailabilityChanged$.pipe(takeUntil(this._serviceDestroyed$));
  }

  /**
   * Getter to indicate configuration changed
   */
  public getMenuCloser$(): Observable<boolean> {
    return this._menuCloser$.pipe(takeUntil(this._serviceDestroyed$));
  }

  /**
   * Close the menu
   */
  public closeMenu(): void {
    this._menuCloser$.next(true);
  }

  /**
   * Shows the size measurements of the current configuration in the scene
   */
  public setMeasurements(showMeasurements: boolean): void {
    this._measurementService.setMeasurementArrows(showMeasurements);
  }

  /**
   * Set view mode in render service
   */
  public setViewMode(newMode: ViewMode): Promise<void> {
    this._renderService.setViewMode(newMode);
    if (this._dataService.configuration) {
      this._renderService.initializeScene(false);
      return this._renderService.loadConfigurationToScene(this._renderService.getCurrentConfiguration(), false);
    } else {
      return;
    }
  }

  public getViewMode$(): Observable<ViewMode> {
    return this._renderService.getViewMode$();
  }

  /**
   * Centers the camera on the complete configuration
   */
  public centerCamera(): void {
    this._renderService.fitCameraToConfiguration(this._renderService.getTotalObjectBox(), ViewMode.cleanNice);
  }

  /**
   * If an x-attachment is selected, it attaches the ModuleVariant for the given Id in the selected spot by letting the rule executor do its job.
   * After that, it calls LoadConfiguration to render the newly created Configuration
   *
   * @param itemId the Id of the variant to be added.
   */
  public addVariantToConfiguration(itemId: Id<ModuleVariant>): void {
    const selectedInfo = this._renderService.getSelectedAttachmentPointInfo();
    if (selectedInfo) {
      // Get the old configuration and placement
      const oldConfig = this._renderService.getCurrentConfiguration();
      const oldConfigPlacement: ConfigurationPlacement = oldConfig.configurationPlacement;
      const currentPlacement = oldConfigPlacement.placement(selectedInfo.placementId);

      // Check if item already in this point. If so, delete, otherwise add
      const filteredAttachments = currentPlacement?.xAttachments ? Array.from(currentPlacement?.xAttachments)
        .filter(t => selectedInfo.childIds?.includes(t[0])) : [];
      const allAttachedVariantIds = filteredAttachments.map(t => t[1].placementId ? oldConfigPlacement.placement(t[1].placementId) : undefined);
      const foundVar = allAttachedVariantIds.find(t => t?.variantId === itemId);

      if (!foundVar) {
        // Let rule executor attach the module and see if it worked
        const createEventResult: EventResult = this._createPlacementEventResult(itemId, selectedInfo, oldConfigPlacement, true);
        this._lastSelectedInfo = selectedInfo;
        // Handle the result
        if (createEventResult.type === 'success') {
          let newConfig = oldConfig.clone();
          newConfig.configurationPlacement = createEventResult.config;
          newConfig = this._setConfigHistory(oldConfig, newConfig);

          void this.loadConfiguration(newConfig, false, createEventResult.ruleState);
        } else if (createEventResult.type === 'RuleError') {
          this._notificationService.showNotification('app.notifications.attachment_failure', Icon.info, NotificationCardType.warning);
          // eslint-disable-next-line no-console
          this._loggingService.debug(`RuleError during placement: ${createEventResult.error}`);
        }
      } else {
        // Try to !
        const result = this._ruleService.removePlacement(
          selectedInfo.placementId, filteredAttachments.find(t => t[1].placementId === foundVar.id)[0], oldConfigPlacement,
          new ConfiguratorRuleData(selectedInfo.xAttachmentId, selectedInfo.placementId)
        );
        this._lastSelectedInfo = selectedInfo;
        let newConfig = oldConfig.clone();

        if (result.type === 'success') {
          newConfig.configurationPlacement = result.config;
          newConfig = this._setConfigHistory(oldConfig, newConfig);
          void this.loadConfiguration(newConfig, false, result.ruleState);
        } else if (result.type === 'RuleError') {
          this._notificationService.showNotification('app.notifications.removal_failure', Icon.info, NotificationCardType.warning);
          // eslint-disable-next-line no-console
          this._loggingService.debug(`RuleError during removal: ${result.error}`);
        }
      }
    } else {
      this._notificationService.showNotification('app.notifications.attachment_points.none_selected', Icon.warn, NotificationCardType.info);
    }
  }

  /**
   * Determines if a variant can be used by the selected attachmentPoint.
   */
  public isVariantValid(itemId: Id<ModuleVariant>): boolean {
    const selectedInfo = this._renderService.getSelectedAttachmentPointInfo();
    if (selectedInfo) {
      // Get the old configuration and placement
      const oldConfig = this._renderService.getCurrentConfiguration();
      const oldConfigPlacement: ConfigurationPlacement = oldConfig.configurationPlacement;
      // using helper function to create placement eventResult
      const createEventResult: EventResult = this._createPlacementEventResult(itemId, selectedInfo, oldConfigPlacement, false);
      // Handle the result
      if (createEventResult.type === 'success') {
        const newConfig = oldConfig.clone();
        newConfig.configurationPlacement = createEventResult.config;

        createEventResult.ruleState.unavailableFatVariants = this._dataService.unavailableFatModuleVariantIds;
        const res = this._ruleService.validateWithRuleEngine(newConfig, createEventResult.ruleState);
        if (res.type === 'failed') {
          return this._ruleService.correctComplete(
            newConfig.configurationPlacement,
            res.errors,
            undefined,
            false,
            createEventResult.ruleState
          ).successful;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  private _createPlacementEventResult(
    itemId: Id<ModuleVariant>,
    selectedInfo: AttachmentPointInfo,
    oldConfigPlacement: ConfigurationPlacement,
    directCall: boolean
  ): EventResult {
    // Get selected attachmentId and placementId
    const selectedXAPId = this._getSelectedXAPId(selectedInfo, itemId, oldConfigPlacement);
    const selectedPlacementId = selectedInfo.placementId;

    // Let rule executor attach the module and see if it worked
    return this._ruleService.createPlacement(
      selectedPlacementId,
      selectedXAPId,
      itemId,
      oldConfigPlacement,
      new ConfiguratorRuleData(selectedXAPId, selectedPlacementId),
      directCall
    );
  }

  /**
   * Determine the selected attachment given the selected AP and variant to add
   */
  private _getSelectedXAPId(
    selectedInfo: AttachmentPointInfo,
    itemId: Id<ModuleVariant>,
    oldConfigPlacement: ConfigurationPlacement
  ): Id<XAttachmentPoint> {
    let selectedAttachmentId: Id<XAttachmentPoint>;
    if (selectedInfo.xAttachmentId) {
      selectedAttachmentId = selectedInfo.xAttachmentId;
    } else if (selectedInfo.childIds) {
      // combined attachment point, so find the first one that fits
      // get the placement that it is attached on
      const targetBp = this._moduleManagementService.getFatModuleVariantById(itemId).blueprint;
      let tps = this._ruleService.checkFitsAndFree(oldConfigPlacement, selectedInfo.placementId, targetBp)
        .filter(t => selectedInfo.childIds.includes(t.x.id));
      if (tps.length === 0) {
        // if there are NO free APs to put it on, instead find one that is taken
        tps = this._ruleService.checkFits(oldConfigPlacement, selectedInfo.placementId, targetBp)
          .filter(t => selectedInfo.childIds.includes(t.x.id));
      }
      selectedAttachmentId = tps[0]?.x?.id;
    }
    return selectedAttachmentId;
  }

  /**
   * Set woodType for configuration
   */
  public setConfigurationWoodType(woodType: WoodType): void {
    const oldConfig: Configuration = this._renderService.getCurrentConfiguration();
    let newConfig: Configuration = oldConfig.clone();

    if (oldConfig.configurationPlacement.woodType !== woodType) {
      newConfig.configurationPlacement.woodType = woodType;
      newConfig = this._setConfigHistory(oldConfig, newConfig);
      this.loadConfiguration(newConfig, false);
    }
  }

  /**
   * If an X attachment is selected, it removes the ModuleVariant from the selected spot.
   * After that, it calls LoadConfiguration to render the newly created Configuration
   */
  public removeSelectedItemFromConfiguration(callback: (success: boolean) => void = () => {
  }): void {
    const selectedInfo = this._renderService.getSelectedAttachmentPointInfo();
    if (selectedInfo) {
      const selectedAttachmentId = selectedInfo.xAttachmentId;
      const selectedPlacementId = selectedInfo.placementId;

      const oldConfig: Configuration = this._renderService.getCurrentConfiguration();
      const oldConfigPlacement: ConfigurationPlacement = oldConfig.configurationPlacement;
      const placementToDeleteFrom = oldConfigPlacement.placements.find(p => p.id === selectedPlacementId);
      if (placementToDeleteFrom?.xAttachments?.size > 0 && placementToDeleteFrom?.xAttachments?.get(selectedAttachmentId)) {
        let newConfig = oldConfig.clone();
        const result = this._ruleService.removePlacement(
          selectedPlacementId,
          selectedAttachmentId,
          oldConfigPlacement,
          new ConfiguratorRuleData(
            selectedAttachmentId,
            selectedPlacementId
          )
        );
        this._lastSelectedInfo = selectedInfo;
        if (result.type === 'success') {
          newConfig.configurationPlacement = result.config;
          newConfig = this._setConfigHistory(oldConfig, newConfig);
          this.loadConfiguration(newConfig, false, result.ruleState);
          callback(true);
        } else if (result.type === 'RuleError') {
          this._notificationService.showNotification('app.notifications.removal_failure', Icon.info, NotificationCardType.warning);
          // eslint-disable-next-line no-console
          this._loggingService.debug(`RuleError during removal: ${result.error}`);
          callback(false);
        }
      } else {
        this._notificationService.showNotification('app.notifications.attachment_points.nothing_to_delete', Icon.trash, NotificationCardType.warning);
        callback(false);
      }
    } else {
      this._notificationService.showNotification('app.notifications.attachment_points.none_selected', Icon.warn, NotificationCardType.warning);
      callback(false);
    }
  }

  public removeMainModuleFromConfiguration(callback: (success: boolean) => void = () => {
  }): void {
    const selectedInfo = this._renderService.getSelectedEditPointInfo();
    if (selectedInfo) {
      const oldConfig: Configuration = this._renderService.getCurrentConfiguration();
      const result = this.getMainModuleRemoveResult(selectedInfo.placementId, oldConfig.configurationPlacement, true);
      if (result.type === 'success') {
        let newConfig = oldConfig.clone();
        newConfig.configurationPlacement = result.config;
        newConfig = this._setConfigHistory(oldConfig, newConfig);
        this._renderService.unsetSelectedPoints();
        this.loadConfiguration(newConfig, false);
        callback(true);
      } else if (result.type === 'RuleError') {
        this._notificationService.showNotification('app.notifications.removal_failure', Icon.info, NotificationCardType.warning);
        // eslint-disable-next-line no-console
        this._loggingService.debug(`RuleError during removal: ${result.error}`);
        callback(false);
      }
    } else {
      this._notificationService.showNotification('app.notifications.attachment_points.none_selected', Icon.warn, NotificationCardType.warning);
      callback(false);
    }
  }

  public getMainModuleRemoveResult(selectedPlacementId: Id<ModulePlacement>, oldConfigPlacement: ConfigurationPlacement, directCall: boolean) {
    return this._ruleService.removeMainModulePlacement(
      selectedPlacementId,
      oldConfigPlacement,
      new ConfiguratorRuleData(
        undefined,
        selectedPlacementId,
        true
      ),
      directCall
    );
  }

  public removeMissingModules(missingModules: ModulePlacement[]) {
    let configPlacement = this._dataService.configuration.configurationPlacement;
    missingModules.map(value => {
      const fatVar = this._dataService.inConfigAndPublishedModuleVariants.find(x => x.variant.id === value.variantId);
      if (fatVar.blueprint.moduleType === ModuleType.Main) {
        const eventRes = this.getMainModuleRemoveResult(value.id, configPlacement, true);
        if (eventRes.type === "success") {
          configPlacement = eventRes.config;
        } else {
          console.log(eventRes.error)
        }
      } else {
        const neighbours = configPlacement.getYNeighboursWithX(value);
        // There should always be one neighbour as it is the module you are attached to
        neighbours.map(x => {
          const eventRes = this._ruleService.removePlacement(x.targetPlacement.id, x.x, configPlacement,
            new ConfiguratorRuleData(x.x, x.targetPlacement.id, true));
          if (eventRes.type === "success") {
            configPlacement = eventRes.config;
          } else {
            console.log(eventRes.error)
          }
        })
      }
    })
    const oldConfig = this._dataService.configuration.clone();
    const newConfig = oldConfig.clone();
    newConfig.configurationPlacement = configPlacement;
    this._dataService.configuration = this._setConfigHistory(oldConfig, newConfig);
  }

  /**
   * Replaces all the given modules
   * @param modulesToReplace a list of modulePlacements which are in the configuration to replace
   * @param noHistory whether the replacement should happen with the ability the undo it
   */
  public replaceOutOfStockModules(modulesToReplace: ModulePlacement[], noHistory: Boolean = false): void {
    let configPlacement = this._dataService.configuration.configurationPlacement;
    modulesToReplace.map(missingModule => {
      const fatVar = this._dataService.inConfigAndPublishedModuleVariants.find(x => x.variant.id === missingModule.variantId);
      if (fatVar.blueprint.moduleType === ModuleType.Main) {
        const replacementVariant = getReplacementVariant(this._dataService.availableFatModuleVariants, fatVar)
        // We need to check that there is a replacement found, if not we can't replace it and should do nothing instead
        if (replacementVariant) {
          const eventRes = this._ruleService.changePlacement(
            missingModule.id, configPlacement, new ConfiguratorRuleData(null, missingModule.id), replacementVariant.id
          )
          if (eventRes.type === "success") {
            configPlacement = eventRes.config;
          } else {
            console.log(eventRes.error);
          }
        }
      } else {
        const neighbours = configPlacement.getYNeighboursWithX(missingModule);
        // In general there should only be one neighbour the module that you are attached to,
        // but it is possible there are no neighbours at this point, as change cascades - as such, it's possible that the relevant missing module
        // is no longer present in the configuration, in which case it has no neighbours and we (correctly) do nothing.
        neighbours.map(neighbour => {
          const eventRes = this._ruleService.changePlacement(
            missingModule.id, configPlacement, new ConfiguratorRuleData(neighbour.x, neighbour.targetPlacement.id)
          );
          if (eventRes.type === "success") {
            configPlacement = eventRes.config;
          } else {
            console.log(eventRes.error);
          }
        })
      }
    })

    const oldConfig = this._dataService.configuration.clone();
    const newConfig = oldConfig.clone();
    newConfig.configurationPlacement = configPlacement;

    this._dataService.configuration = noHistory ? newConfig : this._setConfigHistory(oldConfig, newConfig);
  }

  public replaceVariant(oldPlacementId: Id<ModulePlacement>, filterSetting: Map<Id<Attribute>, string>, changed: Id<Attribute>): void {
    const filterSettingArray: { key: Id<Attribute>, value: string }[] = Array.from(filterSetting, ([key, value]) => (
      {key, value}
    ));

    const oldConfig = this._renderService.getCurrentConfiguration();
    const allFatVariants = this._dataService.inConfigAndPublishedModuleVariants;
    const oldPlacement = oldConfig.configurationPlacement.placements.find(p => p.id === oldPlacementId);
    const oldFatVariant = allFatVariants.find(v => v.variant.id === oldPlacement.variantId);
    const allAvailFatVariants = allFatVariants.filter(fv => this._dataService.allCatalogVarIds.some(v => v === fv.variant.id));
    const variantsInCategory = allAvailFatVariants.filter(fv => fv.blueprint.categoryReference === oldFatVariant.blueprint.categoryReference);

    const fullMatch = variantsInCategory.find(v =>
      filterSettingArray.every(f =>
        v.variant.attributeValues.get(f.key) === f.value
      )
    );

    // Find the most suitable variant if a fullMatch is not possible
    // note: returning the new filterSetting is not needed because the config is reloaded
    const partialMatches = variantsInCategory.filter(v =>
      v.variant.attributeValues.get(changed) === filterSetting.get(changed)
    );
    // Find the best partial match; the one that has the most attributes in common with the chosen filterSettings
    const bestPartialMatch = partialMatches.length === 1 ? partialMatches[0] :
      partialMatches.reduce((bestMatch: { variant: FatModuleVariant, matches: number }, currentPartialMatch) => {
        let currentMatches = 0;
        if (filterSetting && currentPartialMatch.variant.attributeValues) {
          for (const [key, value] of Array.from(filterSetting.entries())) {
            if (currentPartialMatch.variant.attributeValues.get(key) === value) {
              currentMatches++;
            }
          }
        }
        return currentMatches > bestMatch.matches ? {variant: currentPartialMatch, matches: currentMatches} : bestMatch;
      }, {variant: undefined, matches: 0}).variant;

    const newVariant = fullMatch ? fullMatch : bestPartialMatch;

    if (newVariant?.variant) {
      const result = this._ruleService.changePlacement(
        oldPlacementId,
        oldConfig.configurationPlacement,
        new ConfiguratorRuleData(
          null,
          oldPlacementId
        ),
        newVariant.variant.id
      );

      if (result.type === 'success') {
        let newConfig = oldConfig.clone();
        newConfig.configurationPlacement = result.config;
        newConfig = this._setConfigHistory(oldConfig, newConfig);
        this.loadConfiguration(newConfig, false, result.ruleState);
      }
    }
  }

  /**
   * Helper function for recurring code
   */
  private _emitValidityOnStatus(checkResult: CheckResult): void {
    switch (checkResult.type) {
      case "success":
        this._configurationValidityChanged$.next(true);
        break;
      case "failed":
        this._configurationValidityChanged$.next(false);
        break;
    }
  }

  private _emitConfigurationAvailability(configurationVariants: Id<ModuleVariant>[]): void {
    this._configurationAvailabilityChanged$.next(
      !this._dataService.obsoleteFatModuleVariants.filter(v => configurationVariants.includes(v.variant.id)).length &&
      !this._dataService.unavailableFatModuleVariantIds.filter(v => configurationVariants.includes(v)).length
    );
  }

  /**
   * Used for loading a new configuration. The steps are:
   * 1. check if config is Rule Engine OK
   * 1a. if not OK, correct the placement and set it on the config
   * 2. load config to scene incl. camera settings
   * 3. update price
   * 4. update listeners on configuration change
   */
  public async loadConfiguration(configuration: Configuration, setCamera: boolean, ruleState?: RuleState): Promise<void> {
    this._dataService.switchWoodType(configuration.configurationPlacement.woodType);

    const useConfig = configuration.clone(); // make a clone so original object is not affected

    try {
      const checkResult: CheckResult = this._ruleService.validateWithRuleEngine(configuration, ruleState);

      if (checkResult.type === "failed") {
        const selectedInfo = this._renderService.getSelectedAttachmentPointInfo();
        useConfig.configurationPlacement = this._ruleService.correctComplete(
          useConfig.configurationPlacement,
          checkResult.errors,
          selectedInfo ? new ConfiguratorRuleData(
            selectedInfo.xAttachmentId,
            selectedInfo.placementId
          ) : undefined,
          true,
          ruleState
        ).placement;
        this._emitValidityOnStatus(this._ruleService.validateWithRuleEngine(useConfig, ruleState));
      } else {
        this._emitValidityOnStatus(checkResult);
      }

      this._emitConfigurationAvailability(configuration.configurationPlacement.placements.map(p => p.variantId));

      await this._renderService.loadConfigurationToScene(useConfig, setCamera);
      this._renderService.refreshScene();
      this._configurationChanged$.next(useConfig);
    } catch (err) {
      this._loggingService.error(err);
      return Promise.reject("load configuration failed");
    }
    this._logMemory();
  }

  /**
   * Passes the currently loaded configuration to the PersistenceService to save it to the database.
   */
  public saveCurrentConfiguration(modalToOpen: string = 'save-modal'): void {
    const conf = this._renderService.getCurrentConfiguration();

    this._persistenceService.saveConfiguration$(conf).subscribe({
      next: savedCode => {
        // open modal, subscribe to the emitter, and on emit save email
        const modalRef: ModalComponent = this._modalService.open(modalToOpen, {code: savedCode, name: conf.name});
        modalRef.getOutput().pipe(take(1)).subscribe({
          next: x => {
            if (x?.code && x?.name && x?.email) {
              this.updateSavedConfiguration(x.code, x.name, x.email, x.hasAcceptedTerms);
            }
          }
        });
      },
      error: (error: ApolloError) => {
        this.trySaveCount += 1;
        // for first try: keep last 10 history items
        // note that http 413 is returned for a 'request entity too large' error, and that Apollo parses this as an Int
        const isRequestTooLong = (error.networkError as HttpErrorResponse)?.status === 413;
        if (isRequestTooLong && this.trySaveCount === 1) {
          const length = conf.history.length;
          conf.history.reverse().splice(0, length - 10);
          this._notificationService.showNotification('app.notifications.save.undo_stack_part', Icon.save, NotificationCardType.warning);
          this._loggingService.warn('A too large configuration was uploaded and part of the history was deleted');
          this.saveCurrentConfiguration();
          // for second try: remove all history items
        } else if (isRequestTooLong && this.trySaveCount === 2) {
          conf.history = [];
          this._notificationService.showNotification('app.notifications.save.undo_stack', Icon.save, NotificationCardType.warning);
          this._loggingService.warn('A too large configuration was uploaded and the full history was deleted');
          this.saveCurrentConfiguration();
        } else {
          this._notificationService.showNotification('app.notifications.save.failed', Icon.save, NotificationCardType.warning);
        }
        this.trySaveCount = 0;
      }
    });
  }

  public updateSavedConfiguration(
    code: string,
    name: string,
    email: string,
    hasAcceptedTerms: boolean
  ): void {
    this._persistenceService.updateConfiguration$(
      code,
      hasAcceptedTerms,
      name,
      email,
    ).pipe(take(1)).subscribe({
      next: r => {
        if (r) {
          this._renderService.updateCurrentConfiguration(name, email);
        } else {
          this._notificationService.showNotification('app.notifications.update.mail.failed', Icon.upload, NotificationCardType.warning);
        }
      },
      error: _ => {
        this._notificationService.showNotification('app.notifications.update.mail.failed', Icon.upload, NotificationCardType.warning);
      }
    });
  }

  /**
   * Shows the modal to open a configuration by code
   */
  public showOpenModal(): void {
    this._modalService.open('open-modal');
  }

  /**
   * Restores the configuration to the previous configuration.
   */
  public restorePreviousConfiguration(): void {
    this._restorePreviousConfiguration(false);
  }

  /**
   * Restores the configuration to the starting configuration, adding the current configuration to the history.
   */
  public restoreStartConfiguration(): void {
    this._restorePreviousConfiguration(true);
  }

  /**
   * Logs the current memory usage to console (only in Chrome as in non-chromium based browsers this causes issues)
   */
  private _logMemory() {
    // @ts-ignore
    const isChromium = !!window.chrome;
    if (isChromium) {
      /* eslint-disable no-console */
      // We actually need the console to do get memory info
      // @ts-ignore
      const total: number = Math.round(console.memory.totalJSHeapSize / 10485.76) / 100.;
      // @ts-ignore
      const used: number = Math.round(console.memory.usedJSHeapSize / 10485.76) / 100.;
      this._loggingService.debug(`config loaded, current memory: \n{\n\ttotal: ${total} MB\n\tused: ${used} MB\n}`);
      /* eslint-enable no-console */
    }
  }

  /**
   * Copies the old history stack to the new Configuration and adds the old ConfigurationPlacement on top, returning the new Configuration.
   * Items in the history stack have no history.
   */
  private _setConfigHistory(oldConfiguration: Configuration, newConfiguration: Configuration): Configuration {
    // Copy the history
    newConfiguration.history = oldConfiguration.history;
    // Clear old history
    oldConfiguration.history = [];
    // Add old config
    newConfiguration.history.push(new UndoConfiguration(new Date(), oldConfiguration.configurationPlacement));
    return newConfiguration;
  }

  /**
   * Restores a configuration in the current configuration.
   * This removes the current configuration from the call stack, adds the remainder of its history to the first item in the history.
   * If the configuration is restored to start, the current configuration is added to the history stack.
   *
   * @private
   * @param restoreStart Indicates whether the configuration should be reset to the starting configuration.
   */
  private _restorePreviousConfiguration(restoreStart: boolean): void {
    // Get the current configuration and the placement
    const oldConfig: Configuration = this._renderService.getCurrentConfiguration();
    // If restoreStart is false, get 'newest' old configuration and remove (pop) it from history stack
    // If restoreStart is true, revert to 'oldest' old configuration
    // Note that usually the sort should not do much as it is already sorted in most cases.
    const newConfig: Configuration = oldConfig.clone();
    let newConfigPlacement: ConfigurationPlacement;
    if (restoreStart) {
      // We need to use a deep clone here as changes in the oldConfiguration should not propagate down the history stack
      newConfigPlacement = newConfig.history[0]?.configurationPlacement;
    } else {
      newConfigPlacement = newConfig.history.pop()?.configurationPlacement;
    }

    // If there is a configuration in the history, we set is and update the history accordingly.
    if (newConfigPlacement) {
      // Save newConfigPlacement to newConfig
      newConfig.configurationPlacement = newConfigPlacement;
      // Save the history in the new current config and reset the history of the old config (just in case)
      oldConfig.history = [];
      // If we restore to start, we also add the config of the start of this function to the history stack
      if (restoreStart) {
        newConfig.history.push(new UndoConfiguration(new Date(), oldConfig.configurationPlacement.clone()));
      }
      // Set config and render
      this.loadConfiguration(newConfig, false);
    }
  }

  /**
   * Calls apollo service to send a configuration to be ordered to the backend. Returns ConfigurationOrder w/ purchase details.
   */
  public placeConfigurationOrder$(): void {
    const configuration = this._renderService.getCurrentConfiguration();
    const price = this._priceCalculatorService.queryPriceForConfiguration(configuration);

    const publishedModuleVariantsByVariantId = new Map(
      this._dataService.inConfigAndPublishedModuleVariants.map(fv => [fv.variant.id, fv]),
    );
    const publishedModuleVariantsByBlueprintId = new Map(
      this._dataService.inConfigAndPublishedModuleVariants.map(fv => [fv.blueprint.id, fv]),
    );

    const placementInfo = configuration.configurationPlacement
      .getPlacementsInfo(publishedModuleVariantsByBlueprintId, publishedModuleVariantsByVariantId);

    // activate spinner - note that we can't use the GQL observable directly, as the spinner also subscribes!
    const dummyIndicator = new EventEmitter<void>();
    this._applicationStateService.activateGeneralSpinner(dummyIndicator);

    const flowType = this._dataService.currentRetailerOptions.webhookOptions.flowType;
    // do request
    this._apolloService.placeConfigurationOrder$(configuration, price, placementInfo)
      .pipe(take(1), takeUntil(this._serviceDestroyed$)).subscribe({
      next: c => {
        if (c.order.price + (c.order.installationCost ?? 0) === price) {
          switch (flowType) {
            case WebhookFlowType.magentotwophaseredirect:
              this._postTwoPhaseRedirect(c.payload);
              break;
            case WebhookFlowType.codegetredirect:
              if (c.order.code) {
                this._codeGetRedirect(c.order.code, this._extractAndStringifyParams(window.location.href));
              } else {
                this._notificationService.showNotification("app.cart.error", Icon.warn, NotificationCardType.warning);
              }
              break;
            case WebhookFlowType.gammasendmessage:
              this._gammaHandlerService.parseAndSendMessage(c.payload);
              break;
            case WebhookFlowType.hornbachsendmessage:
              //We abuse the payload to put in the url which we receive from the Hornbach Integration Service
              if(c.payload) {
                window.open(JSON.parse(c.payload), "_self");
              } else {
                this._notificationService.showNotification("app.cart.error", Icon.warn, NotificationCardType.warning);
              }
              break;
          }
        } else {
          this._notificationService.showNotification("app.cart.priceError", Icon.warn, NotificationCardType.warning);
        }
        dummyIndicator.emit();
      },
      error: (e: ApolloError) => {
        const code: string | undefined = (e && e.graphQLErrors && e.graphQLErrors.length > 0) ? e.graphQLErrors[0].extensions.code as string : undefined;
        if (code && code.endsWith("noFreeEANCode")) {
          this._notificationService.showNotification("app.cart.noFreeEANCode", Icon.warn, NotificationCardType.warning);
        } else {
          this._notificationService.showNotification("app.cart.error", Icon.warn, NotificationCardType.warning);
        }
        dummyIndicator.emit();
      }
    });
  }

  private _codeGetRedirect(code: string, parameters: string): void {
    setTimeout(() => {
      window.open(`${this._dataService.currentRetailerOptions.webhookOptions.webhookUrls.redirectUrl}?configCode=${code}&` + parameters, "_blank");
    });
  }

  /**
   * Redirects user to webshop.
   */
  private _postTwoPhaseRedirect(payload: string): void {
    this._http.post(this._dataService.currentRetailerOptions.webhookOptions.webhookUrls.redirectUrl, payload).subscribe({
      next: (r: { status?: string, return_url?: string }) => {
        // If successful, we redirect with same payload
        if (r?.status === "success" && r?.return_url) {
          const mapInput = document.createElement("input") as HTMLInputElement;
          mapInput.type = "hidden";
          mapInput.name = "jsoncart";
          mapInput.setAttribute("value", payload);
          this._redirectNewTab(r.return_url, "POST", mapInput);
        } else {
          this._notificationService.showNotification("app.cart.error", Icon.warn, NotificationCardType.warning);
          this._loggingService.warn(`Failed to add to cart (${JSON.stringify(r)}) with payload: ${payload}`);
        }
      }, error: (e) => {
        this._notificationService.showNotification("app.cart.error", Icon.warn, NotificationCardType.warning);
        this._loggingService.error(e); // So we find it in Sentry
      }
    });
  }


  /**
   * Opens an url in a new tab with given payload.
   * Note that we need to use a very curious construction to accommodate iOS, since giving a target to the form
   * _does not work_ because of ios' strict popup rules. As such, we need to:
   * 1. open a new window from a separate onClick function, inside a separate async function call (don't even get me started...)
   * 2. inject the form, with payload, into that newly opened window
   * 3. submit the window to _self instead of _blank, so that results are opened in the new (now current) window
   * 4. manually set up a ClickEvent for our dummy div to trigger the onClick
   */
  private _redirectNewTab(url: string, method: string, payload?: HTMLInputElement): void {
    const dummy = document.createElement('div');
    dummy.onclick = (ev) => setTimeout(() => {
      const newWindow = window.open('', '_blank');
      const mapForm = newWindow.document.createElement("form");
      mapForm.id = "popupFormId";
      mapForm.method = method;
      mapForm.action = url;
      if (payload) {
        mapForm.appendChild(payload);
      }
      newWindow.document.body.appendChild(mapForm);

      mapForm.submit();
    });

    const dispatch = document.createEvent("HTMLEvents");
    dispatch.initEvent("click", true, true);
    dummy.dispatchEvent(dispatch);
  }


  private _extractAndStringifyParams(url: string): string {
    const urlObj = new URL(url); // Parse the URL
    const searchParams = urlObj.search.split('?')[1]; // Get the search part (query string)

    // If there are no parameters, return an empty string
    return searchParams ? searchParams : '';
  }

  public ngOnDestroy() {
    this._serviceDestroyed$.next();
  }
}
