import gql from "graphql-tag";

export const MATRIX_FIELDS = gql`
  fragment matrixFragment on Matrix4x4 {
    r1 {
      c1
      c2
      c3
      c4
    }
    r2 {
      c1
      c2
      c3
      c4
    }
    r3 {
      c1
      c2
      c3
      c4
    }
    r4 {
      c1
      c2
      c3
      c4
    }
  }
`;

export const VECTOR_FIELDS = gql`
  fragment vec3Fragment on Vec3 {
    x
    y
    z
  }
`;

export const ASSET_FIELDS = gql`
  fragment assetFragment on Asset {
    id
    filename
    directory
    mimeType
    url
  }
`;

export const MODULE_VARIANT_FIELDS = gql`
    ${ASSET_FIELDS}
    fragment moduleVariantFragment on ModuleVariant {
        id
        blueprintId
        name
        price
        catalogIcon {
            ...assetFragment
        }
        visualChanges {
            __typename
            ... on Invisible {
                name
            }
            ... on MaterialChange {
                changes {
                    values {
                        value
                        key
                    }
                }
            }
            ... on Translation {
                translate {
                    x
                    y
                    z
                }
                translateModule
                xaps
                yaps
            }
        }
        attributeValues {
            values {
                key
                value
            }
        }
        showInCatalog
        translations {
            values  {
                key
                value
            }
        }
        groupId
    }
`;

export const MODULE_BLUEPRINT_FIELDS = gql`
  ${MODULE_VARIANT_FIELDS}
  ${VECTOR_FIELDS}
  ${ASSET_FIELDS}
  fragment moduleBlueprintFragment on ModuleBlueprint {
    id
    name
    status {
        __typename
        ...on Archived {
            version
        }
    }
    categoryId
    categoryReference
    featureImage {
      ...assetFragment
    }
    xAttachmentPoints {
      id
      position {
        ...vec3Fragment
      }
      orientation {
        ...vec3Fragment
      }
      properties
    }
    yAttachmentPoints {
      id
      position {
        ...vec3Fragment
      }
      orientation {
        ...vec3Fragment
      }
      properties
    }
    variantsPerWoodType {
      values {
        key
        value {
          ...moduleVariantFragment
        }
      }
    }
    model {
      ...assetFragment
    }
    visualXAttachmentPoints {
      position {
        x
        y
        z
      }
      orientation {
        x
        y
        z
      }
      xAttachmentPointIds
    }
    visualYAttachmentPoint {
      position {
        x
        y
        z
      }
      orientation {
        x
        y
        z
      }
      yAttachmentPointId
    }
    boundingBoxes {
      position {
        x
        y
        z
      }
      size {
        width
        height
        length
      }
      color {
          color
          id
      }
    }
    moduleType
    groupId
    translationKeys {
      key
      value
    }
    considerAsFree
  }
`;

export const CATALOG_FIELDS = gql`
  ${ASSET_FIELDS}
  fragment catalogFragment on Catalog {
    id
    woodTypeCatalogs {
      values {
        key
        value {
          categories {
            title
            moduleCategories {
              id
              reference
              order
              customizable
              moduleVariantIds
            }
          }
          filteredVariants {
            values {
              moduleCategoryReference: key
              value {
                attributeId
                variantIds
                value
              }
            }
          }
          filterSettings {
            values {
              moduleCategoryReference: key
              value {
                attributes {
                  values {
                    attributeId: key
                    value {
                      valueIcons {
                        values {
                          attributeValue: key
                          iconAsset: value {
                            ...assetFragment
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CONFIGURATION_PLACEMENT_FIELDS = gql`
  ${MATRIX_FIELDS}
  fragment configurationPlacementFragment on ConfigurationPlacement {
    placements {
      id
      variantId
      xAttachments {
        values {
          key
          value {
            placementId
            yAttachmentId
          }
        }
      }
      transformation {
        ...matrixFragment
      }
    }
    originPlacement
    woodType
  }
`;

export const CONFIGURATION_FIELDS = gql`
  ${CONFIGURATION_PLACEMENT_FIELDS}
  ${VECTOR_FIELDS}
  fragment configurationFragment on Configuration {
    id
    name
    code
    configurationPlacement {
      ...configurationPlacementFragment
    }
    history {
      date
      configurationPlacement {
        ...configurationPlacementFragment
      }
    }
    renderState {
      cameraPosition {
        ...vec3Fragment
      }
      cameraRotation {
        ...vec3Fragment
      }
      controlTarget {
        ...vec3Fragment
      }
    }
    isPreConfiguration
    installationService
  }
`;
