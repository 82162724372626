import {Injectable, OnDestroy} from '@angular/core';
import {
  Configuration,
  ConfigurationPlacement,
  ConfiguratorRuleData,
  FatModuleVariant,
  Id,
  ModulePlacement,
  ModuleVariant,
  RetailerOptions,
  WoodType,
  woodTypes
} from "@ess/jg-rule-executor";
import {firstValueFrom, Subject} from "rxjs";
import {PersistenceService} from "@src/app/services/persistence/persistence.service";
import {ApolloService} from "@src/app/services/apollo/apollo.service";
import {ModelLoadingService} from "@src/app/services/model-loading/model-loading.service";
import {
  FailedToFetchBlueprintModels,
  FailedToFetchCurrentScopeId,
  FailedToFetchTranslations,
  FailedToRetrieveBlueprintsError,
  FailedToRetrieveCatalogError,
  FailedToRetrieveConfigurationByCodeError,
  FailedToRetrieveObsoleteBlueprintError,
  FailedToRetrieveObsoleteVariantError
} from "@src/app/model/errors";
import {ApplicationStateService} from "@src/app/services/applicationstate/application-state.service";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {ApplicationState} from "@src/app/model/applicationstate";
import {InteractionService} from "@src/app/services/interaction/interaction.service";
import {NotificationService} from "@src/app/services/notification/notification.service";
import {Icon} from "@src/app/library/components/icon";
import {NotificationCardType} from "@src/app/components/notification-card/notification-card.type";
import {RuleService} from "@src/app/services/rule-execution/rule.service";
import {TranslateService} from "@ngx-translate/core";
import {DEFAULT_CONFIG_CODE, SCOPE_NOT_FOUND_REDIRECT} from "@src/app/constants";
import {CustomStyleService} from "@src/app/services/custom-style/custom-style.service";
import {RenderService} from "@src/app/services/render/render.service";
import {filter, take, takeUntil} from "rxjs/operators";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {ModuleBlueprintWithWoodVariants} from "@src/app/services/utils/gqlConverter.util";
import {ConfiguratorCatalog} from "@src/app/model/configurator-catalog";
import {Title} from "@angular/platform-browser";
import {ModalComponent} from "@src/app/components/modal/modal.component";
import {ModalService} from "@src/app/services/modal/modal.service";
import {ViewMode} from "@src/app/model/view-mode";
import {getReplacementVariant, isMissing} from "@ess/jg-rule-executor/dist/src/utils/check/deprecation";
import {ModuleManagementService} from "@src/app/services/module-management/module-management.service";

@Injectable({
  providedIn: 'root'
})
export class EntryPointService implements OnDestroy {
  private _serviceDestroyed$: Subject<void> = new Subject();

  constructor(
    private _stateService: ApplicationStateService,
    private _dataService: InitDataService,
    private _apolloService: ApolloService,
    private _persistenceService: PersistenceService,
    private _modelLoadingService: ModelLoadingService,
    private _interactionService: InteractionService,
    private _notificationService: NotificationService,
    private _ruleService: RuleService,
    private _translateService: TranslateService,
    private _customStyleService: CustomStyleService,
    private _renderService: RenderService,
    private _loggingService: LoggingService,
    private _modalService: ModalService,
    private _title: Title,
    private _moduleManagementService: ModuleManagementService
  ) {
  }

  /**
   * Initialize the application.
   */
  public async firstTimeInitializeApp$(
    retailerOptions: RetailerOptions,
    codeToLoad?: string,
    loadMarkers: boolean = false,
    downloadUsdz: boolean = false
  ): Promise<void> {
    await this.initializeApp$(retailerOptions, codeToLoad).catch(this._handleInitFailure);
    this._interactionService.getViewMode$()
      .pipe(
        filter(v => {
          return v === ViewMode.edit && this._dataService.currentPreConfigurationCode && !this._dataService.configuration
        }),
        takeUntil(this._serviceDestroyed$),
      )
      .subscribe(_ => this._loadConfig$(loadMarkers, downloadUsdz))
  }

  private async _loadConfig$(loadMarkers: boolean = false, downloadUsdz: boolean = false): Promise<void> {
    this._initializeConfig$(this._dataService.currentPreConfigurationCode, loadMarkers)
      .then(async _ => {
        // start building
        await this._interactionService.loadConfiguration(this._dataService.configuration, true);
        if (downloadUsdz) {
          await this._renderService.downloadUsdzScene();
        }
        this._stateService.updateState(ApplicationState.appInitialized);
        await this._postInitializeApp$();
        // we reload the scene again to make sure the APs show up
        this._renderService.refreshScene();
      }).catch(e => {
      this._loggingService.log('Encountered an error while initializing the configuration: ' + e);
    });
  }

  private _handleInitFailure(e: Error): void {
    this._loggingService.log("Encountered an error while initializing the app: " + e);

    // If the scope is the issue, redirect to a specified site
    if (e instanceof FailedToFetchCurrentScopeId) {
      if (window.confirm(
        `Scope (${this._dataService.currentScope}) cannot be used, you will be redirected to: ${SCOPE_NOT_FOUND_REDIRECT}.`
      )) {
        window.location.href = SCOPE_NOT_FOUND_REDIRECT;
      }
    }
  }

  /**
   * OnDestroy close subscriptions
   */
  ngOnDestroy(): void {
    this._serviceDestroyed$.next();
    this._serviceDestroyed$.complete();
  }

  /**
   * Construct map of woodType to list of fat variants, from an array of ModuleBlueprintWithWoodVariants
   */
  private _makeWoodTypeFatVariants(
    modules: ModuleBlueprintWithWoodVariants[]
  ): Map<WoodType, FatModuleVariant[]> {
    const woodTypeMap = new Map<WoodType, FatModuleVariant[]>()
    woodTypes.forEach(woodType => {
      const currentList = modules.flatMap(mbp => {
        const variants = mbp.variants.get(woodType)
        return variants ? variants.map(variant => new FatModuleVariant(variant, mbp.blueprint)) : []
      });
      woodTypeMap.set(woodType, currentList);
    });
    return woodTypeMap;
  }

  /**
   * Retrieves missing variants from backend. Returns a list of fat variants per woodType.
   */
  private async _processMissingVariants(missingVariantIds: Id<ModuleVariant>[]): Promise<Map<WoodType, FatModuleVariant[]>> {
    const missingFatVariantEntries = await Promise.all(missingVariantIds.map((vId) => firstValueFrom(this._apolloService.fetchVariantById$(vId)).then(
      async variantPerWoodType => {
        const anyVariant = Array.from(variantPerWoodType.values())[0];
        return await firstValueFrom(this._apolloService.fetchBlueprintById$(anyVariant.blueprintId)).then(
          blueprint => {
            this._stateService.incrementProgress(1, missingVariantIds.length);
            return Array.from(variantPerWoodType.entries()).map(([woodType, variant]) => [
              woodType, new FatModuleVariant(variant, blueprint.blueprint)
            ]) as [WoodType, FatModuleVariant][];
          },
          e => Promise.reject(new FailedToRetrieveObsoleteBlueprintError(e)));
      },
      e => Promise.reject(new FailedToRetrieveObsoleteVariantError(e)))));

    return woodTypes.reduce((outerMap, outerWoodType) => {
      outerMap.set(outerWoodType, []);
      return missingFatVariantEntries.flat().reduce((map, [woodType, fatVariant]) => {
        const existing = map.get(woodType) || [];
        map.set(woodType, [...existing, fatVariant]);
        return map;
      }, outerMap);
    }, new Map<WoodType, FatModuleVariant[]>());
  }

  /**
   * Extracts variant ids from catalog.
   */
  private _makeWoodTypeModuleVariantIdsFromCatalog(catalogs: Map<WoodType, ConfiguratorCatalog>): Map<WoodType, Id<ModuleVariant>[]> {
    const entries = woodTypes.map((woodType) => {
      const catalog = catalogs.get(woodType);
      if (catalog) {
        const moduleVariantIds = catalog.categories.map((c) => c.moduleCategories.map((mc) => mc.moduleVariantIds).flat()).flat();
        return [
          woodType,
          moduleVariantIds
        ] as [WoodType, Id<ModuleVariant>[]];
      } else {
        return [woodType, []] as [WoodType, Id<ModuleVariant>[]];
      }
    });
    return new Map<WoodType, Id<ModuleVariant>[]>(entries);
  }

  /**
   * Initialize the application up until the point where the user can start interacting with the model.
   */
  public async initializeApp$(retailerOptions: RetailerOptions, codeToLoad?: string): Promise<void> {
    try {
      // Reset the state: handles the situation where the function might be triggered from the app.
      this._stateService.updateState(ApplicationState.init);

      // First we get and set the current scope and retailer options
      await firstValueFrom(this._apolloService.getCurrentScopeId$()).then(se => {
        this._dataService.currentScope = se;
      }, e => Promise.reject(new FailedToFetchCurrentScopeId(e)));

      // Now load textures and materials
      await firstValueFrom(this._apolloService.getAllTexturesAndMaterials$()).then(tm => {
        this._modelLoadingService.setTexturesAndMaterials(tm);
        this._stateService.updateState(ApplicationState.materialsAndTexturesLoaded);
      });

      this._dataService.currentRetailerOptions = retailerOptions;
      this._modelLoadingService.setResultSceneTextures();

      if (retailerOptions.configuratorOptions?.favicon?.url) {
        this._renderService.setFavicon(retailerOptions.configuratorOptions.favicon.url);
      }
      this._renderService.setBackground(
        retailerOptions.colorCodes.gradient2,
        retailerOptions.colorCodes.gradient1,
        retailerOptions.colorCodes.gradient2
      );

      // Set custom style for the app
      this._customStyleService.setCustomStyle(this._dataService.currentRetailerOptions);
      this._stateService.updateState(ApplicationState.currentScopeAndRetailerAreLoaded);

      // Get the correct translation files
      const translations = await firstValueFrom(this._apolloService.getTranslations$()).catch(e => Promise.reject(new FailedToFetchTranslations(e)));
      this._sanitizeTranslationsObjectRecursively(translations);
      this._translateService.setTranslation(this._translateService.currentLang, translations, true);
      this._title.setTitle(this._translateService.instant('app.page_title'));
      this._stateService.updateState(ApplicationState.translationsAreLoaded);

      this._dataService.showPlp = (retailerOptions.preconfigurations.length > 1) && !retailerOptions.configuratorOptions.hideSteps;

      //Determine what needs to be shown initially
      if (codeToLoad) {
        //If a code is given in the URL load that configuration
        this._dataService.currentPreConfigurationCode = codeToLoad;
        await this._interactionService.setViewMode(ViewMode.edit);
      } else if (retailerOptions.preconfigurations.length === 1 && !this._dataService.currentPreConfigurationCode) {
        this._dataService.showPlp = false;
        this._dataService.currentPreConfigurationCode = retailerOptions.preconfigurations[0];
        await this._interactionService.setViewMode(ViewMode.edit);
      } else if (retailerOptions.preconfigurations.length === 0 && !this._dataService.currentPreConfigurationCode) {
        this._dataService.showPlp = false;
        this._dataService.currentPreConfigurationCode = DEFAULT_CONFIG_CODE;
        await this._interactionService.setViewMode(ViewMode.edit);
      } else {
        await this._interactionService.setViewMode(ViewMode.plp);
      }
      // Tell the system we are ready to go
      return Promise.resolve();
    } catch (e) {
      this._loggingService.error(e);
      return Promise.reject(e);
    }
  }

  private async _initializeConfig$(configCode: string, loadMarkers: boolean): Promise<void> {
    let obsoleteVariants: Id<ModuleVariant>[] = [];
    try {
      // Then we retrieve the configuration
      await this._getConfigByCode(configCode);

      // Load markers if needed
      if (loadMarkers) {
        this._dataService.markerPlacement = await this._apolloService.getConfigurationMarkers$(configCode);
      }

      // Retrieve the catalog (which also contains the variantIds)
      await firstValueFrom(this._apolloService.fetchCatalog$(this._dataService.currentRetailerOptions.purchasePriceMandatory))
        .then(catalog => {
            this._dataService.woodTypeCatalogs = catalog;
            this._dataService.woodTypeModuleVariantIds = this._makeWoodTypeModuleVariantIdsFromCatalog(catalog);
            this._stateService.updateState(ApplicationState.catalogAndVariantsLoaded);
          },
          e => Promise.reject(new FailedToRetrieveCatalogError(e))
        );

      // Retrieve the published blueprints
      await firstValueFrom(this._apolloService.fetchAllPublishedModuleBlueprints$())
        .then(blueprints => {
            this._dataService.woodTypePublishedFatVariants = this._makeWoodTypeFatVariants(blueprints);
            // Use the current config's woodType for now
            this._dataService.publishedFatModuleVariants = this._dataService.woodTypePublishedFatVariants.get(
              this._dataService.configuration.configurationPlacement.woodType
            );

            this._stateService.updateState(ApplicationState.currentBlueprintsLoaded);
          },
          e => Promise.reject(new FailedToRetrieveBlueprintsError(e))
        );

      // Retrieve the available blueprints
      await firstValueFrom(this._apolloService.fetchAllAvailableModuleBlueprints$())
        .then(
          blueprints => {
            this._dataService.woodTypeAvailableFatVariants = this._makeWoodTypeFatVariants(blueprints);
            // Use the current config's woodType for now
            this._dataService.availableFatModuleVariants = this._dataService.woodTypeAvailableFatVariants.get(
              this._dataService.configuration.configurationPlacement.woodType
            );
          },
          e => Promise.reject(new FailedToRetrieveBlueprintsError(e))
        );

      // Determine and load any missing Variants/Blueprints from the config and undo-stack
      obsoleteVariants = ModelLoadingService.determineMissingVariantIds(
        this._dataService.configuration,
        this._dataService.publishedFatModuleVariants
      );
      // Determine and load the obsolete FatVariants from the current config and history
      this._dataService.obsoleteWoodTypeFatVariants = await this._processMissingVariants(obsoleteVariants);

      // Retrieve and store all out-of-stock variants - rule executor needs these for variant replacement
      this._dataService.unavailableWoodTypeFatVariantIds = await firstValueFrom(this._apolloService.fetchUnavailableModuleVariantIds$());

      // Set the current woodType - this will also fill various other 'current' dataService fields
      // with values retrieved from the corresponding woodType map
      this._dataService.switchWoodType(this._dataService.configuration.configurationPlacement.woodType);

      this._stateService.updateProgress(obsoleteVariants.length, obsoleteVariants.length);
      this._stateService.updateState(ApplicationState.configFatVariantsLoaded);

      // Next, the Assets (models) of the used blueprints are fetched.
      const configBlueprints = this._modelLoadingService.getConfigFatVariantList(this._dataService.configuration, [
        ...this._dataService.publishedFatModuleVariants,
        ...this._dataService.obsoleteFatModuleVariants
      ])?.map(fv => fv.blueprint);

      // Throw an error in case of MalformedConfigError
      if (!configBlueprints) {
        return Promise.reject();
      }

      // Actually retrieve the 3D models
      await this._modelLoadingService.fetchModels$(new Set(configBlueprints),
        () => this._stateService.incrementProgress(1, configBlueprints.length)
      ).then(_ => {
          this._stateService.updateState(ApplicationState.configAssetsLoaded);
          this._modelLoadingService.notifyFailedBlueprintsIfAny();
        },
        e => Promise.reject(new FailedToFetchBlueprintModels(e))
      );

      // Initialize the rule service if applicable
      if (!this._dataService.noRulesAndValidation) {
        await this._ruleService.initialize();
      } else {
        await Promise.resolve();
      }
      this._stateService.updateState(ApplicationState.rulesAreLoaded);

      await this._handleDeprecatedAndOutOfStockModules();

      // Tell the system we are ready to go
      return Promise.resolve();
    } catch (e) {
      this._loggingService.error(e);
      return Promise.reject(e);
    }
  }

  /**
   * Do post initialization work. I.e. load models/assets not used in the configuration.
   */
  private async _postInitializeApp$(): Promise<void> {
    const configBlueprints = this._modelLoadingService.getConfigFatVariantList(this._dataService.configuration, [
      ...this._dataService.publishedFatModuleVariants,
      ...this._dataService.obsoleteFatModuleVariants
    ])?.map(fv => fv.blueprint);

    // Throw an error in case of MalformedConfigError
    if (!configBlueprints) {
      return Promise.reject();
    }

    // Next, load the remaining Assets
    const stillToLoad = this._dataService.publishedFatModuleVariants.filter(
      cbp => !configBlueprints.find(confB => confB.id === cbp.blueprint.id)
    ).map((fv) => fv.blueprint);

    // If needed, preload everything else
    if (!this._dataService.noDataPreload) {
      await this._modelLoadingService.fetchModels$(new Set(stillToLoad),
        () => this._stateService.incrementProgress(1, stillToLoad.length)
      ).then(_ => {
          this._stateService.updateState(ApplicationState.fullyOperational);
          this._modelLoadingService.notifyFailedBlueprintsIfAny();
        },
        e => Promise.reject(new FailedToFetchBlueprintModels(e))
      );
    } else {
      this._stateService.updateState(ApplicationState.fullyOperational);
    }

    // Tell the system we are ready to go
    return Promise.resolve();
  }

  /**
   * Gets a specified config, or the default.
   * shows notifications on fail and success
   */
  private async _getConfigByCode(configCode: string): Promise<void> {
    await firstValueFrom(this._persistenceService.getConfigurationByCode$(configCode))
      .then(
        config => {
          this._dataService.configuration = config;

          if (config) {
            this._stateService.updateState(ApplicationState.plainConfigLoaded);
            this._stateService.getState$()
              .pipe(filter(t => t === ApplicationState.appInitialized), take(1))
              .subscribe(_ => {
                // Show success message when the config isn't the default.
                if (config.code !== DEFAULT_CONFIG_CODE && !this._dataService.currentRetailerOptions?.preconfigurations.includes(config.code)) {
                  this._notificationService.showNotification('app.notifications.load.valid_config', Icon.edit, NotificationCardType.info);
                }

                // Show warning message when wood type of the config isn't available.
                const availableWoodTypes = this._dataService.currentRetailerOptions.woodTypesAvailable
                  .filter(w => w.available === true)
                  .map(w => w.woodType);

                if (!availableWoodTypes.includes(config?.configurationPlacement?.woodType)) {
                  this._notificationService.showNotification('app.notifications.load.wood_type_unavailable', Icon.woodtype, NotificationCardType.warning);
                }
              });
          }
        },
        async e => {
          this._stateService.getState$()
            .pipe(filter(t => t === ApplicationState.appInitialized), take(1))
            .subscribe(_ => {
              this._notificationService.showNotification('app.notifications.load.invalid_config', Icon.edit, NotificationCardType.warning);
            });
          return Promise.reject(new FailedToRetrieveConfigurationByCodeError(configCode, e));
        }
      )
      .finally(async () => {
        // Only execute if there is no configuration and the configCode is not the default id.
        if (!this._dataService.configuration && configCode !== DEFAULT_CONFIG_CODE) {
          // Open modal, subscribe to the emitter, and on emit find either the newly chosen config or the default config
          const modalRef: ModalComponent = this._modalService.open('open-modal', {code: configCode});
          const modalOutput = await firstValueFrom(modalRef.getOutput());

          await this._getConfigByCode(modalOutput.newConfigCode ?? DEFAULT_CONFIG_CODE);

          if (!this._dataService.configuration) {
            this._loggingService.warn(
              'Configuration could not be loaded (c: ' + configCode + ' and d: ' + DEFAULT_CONFIG_CODE + ') for scope: ' + this._dataService.currentScope
            );
          }
        }
      });
  }

  /**
   * Replaces all the archived and unavailable placements in the configuration, if rules are enabled
   */
  private async _handleDeprecatedAndOutOfStockModules(): Promise<void> {
    if (this._dataService.noRulesAndValidation) return;
    // Replace the archived placements, if they can't be replaced they are removed
    this._dataService.configuration = this._replaceDeprecatedVariants(this._dataService.configuration)
    // _determineNotAvailablePlacementsInConfiguration should only include modules which are out of stock as all archived
    // placements should already be replaced or removed. We replace without history, as that's what was requested by Thomas
    this._interactionService.replaceOutOfStockModules(this._determineNotAvailablePlacementsInConfiguration(), true);
  }

  /**
   * Looks at the configuration and returns all the modules in it, which are not in the AvailableFatModuleVariants
   */
  private _determineNotAvailablePlacementsInConfiguration(): ModulePlacement[] {
    const configPlacement = this._dataService.configuration.configurationPlacement;
    const availableVariantIds = this._dataService.availableFatModuleVariants.map(fatVar => fatVar.variant.id);
    return configPlacement.placements.filter(
      placement => !availableVariantIds.includes(placement.variantId)
    );
  }

  private _sanitizeTranslationsObjectRecursively(obj: object) {
    // Iterate through each key in the object
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // Check if the value is an object, if so, recursively call transformLeaves
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          this._sanitizeTranslationsObjectRecursively(obj[key]);
        } else if (typeof obj[key] === 'string') { // If the value is a string, apply the transformation function
          obj[key] = this._renderService.sanitizeString(obj[key]);
        }
      }
    }
  }

  /**
   * Looks for deprecated / missing variants and replaces them with the newest version of the variant.
   * - if there is no new version, the old one will be removed.
   */
  private _replaceDeprecatedVariants(configuration: Configuration): Configuration {
    const irreplaceablePlacements: ModulePlacement[] = [];
    configuration.configurationPlacement.placements = configuration.configurationPlacement.placements.map(p => {
      const fatVariant = this._moduleManagementService.getFatModuleVariantById(p.variantId);
      if (isMissing(this._dataService.obsoleteFatModuleVariants, fatVariant)) {
        const replacementVariant = getReplacementVariant(this._dataService.availableFatModuleVariants, fatVariant);
        if (replacementVariant) {
          p = new ModulePlacement(
            p.id,
            replacementVariant.id,
            p.transformation,
            p.xAttachments
          );

          // Show a notification if the loaded config was made by the user
          if (!configuration.isPreConfiguration && configuration.history.length > 0) {
            this._notificationService.showNotification(
              "app.notifications.load.replaced_variant", Icon.edit, NotificationCardType.info, {name: fatVariant.blueprint.name}
            );
          }
        } else {
          irreplaceablePlacements.push(p);
        }
      }
      return p;
    });

    irreplaceablePlacements.map(ip => {
      // The attachment info of the current placement
      configuration.configurationPlacement = this._removePlacement(configuration, ip);
    });

    return configuration;
  }

  /**
   * Remove placement from configuration using only the placement itself.
   * AttachmentPoint will be retrieved using the placement
   *
   * @param configuration
   * @param placementToRemove
   * @private
   */
  private _removePlacement(configuration: Configuration, placementToRemove: ModulePlacement): ConfigurationPlacement {
    const yWithX = configuration.configurationPlacement.getYNeighboursWithX(placementToRemove)[0];
    const oldConfig = configuration.clone();
    const result = this._ruleService.removePlacement(
      yWithX.targetPlacement.id,
      yWithX.x,
      oldConfig.configurationPlacement,
      new ConfiguratorRuleData(
        yWithX.x,
        yWithX.targetPlacement.id
      )
    );

    if (result.type === 'success') {
      this._notificationService.showNotification(
        "app.notifications.load.removed_variant",
        Icon.edit,
        NotificationCardType.info
      );
      return result.config;
    } else {
      return configuration.configurationPlacement;
    }
  }
}
